:root {
  --clear: rgb(0, 0, 0, 0);
  --primary: rgb(33, 37, 41, 1);
  --secondary: orange;
  --navbar: rgb(20, 20, 20, 0.8);
}

/* Font Library: */
@font-face {
  font-family: OpenSans;
  src: url("./assets/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf");
}

* {
  font-family: OpenSans;
}

.brand {
  font-size: 28px;
}

/* Class: */
.bg-card-overlay {
  background-color: var(--primary)
}

.bg-orange {
  background-color: orange;
}

.color-grey {
  color: grey;
}

.color-white {
  color: white;
}

.interaction:hover {
  color: var(--secondary);
  cursor: pointer;
}

.navbar {
  background: var(--navbar)
}

.textbox {
  background-color: rgb(255, 255, 255, 0.6);
}

/* ID: */
#hero {
  background: linear-gradient(to bottom,
    var(--clear),
    white
  ), url("./assets/img/hero.png");
  background-size: auto 100vh;
  background-position: top center;
  background-repeat: no-repeat;
}

#wrapper {
  background: black;
}

#wrapper-hero {
  background: white;
}

/* Classes: */
.card > img {
  border: 0;
  border-top-left-radius: 2rem !important;
  border-top-right-radius: 2rem !important;
}

.card, .card-body {
  border: 0;
  border-bottom-left-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important;
}

/* Buttons: */
.btn {
  box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.8) !important;
  border: 0;
  border-radius: 500px;
}

.btn-secondary:hover {
  background-color: var(--secondary) !important;
}